hr {
    margin: 0;
}

.card {
    background-color: whitesmoke;
    transition: box-shadow .5s;
    box-shadow: 0 0 11px rgba(33, 33, 33, .1);
}

.card h5 {
    text-shadow: 1px 1px 1px red;
}

a {
    color: black;
}

a:hover {
    text-decoration: none;
}

#coupons .lazy-load-image-background {
    margin-left: auto;
    margin-right: auto;
}

#coupons .lazy-load-image-background img {
    width: 100%;
}

.card-img-top {
    width: 60%;
    margin-top: 10%;
    margin-bottom: 10%;
    margin-left: auto;
    margin-right: auto
}