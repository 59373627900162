a:hover {
    text-decoration: none;
}

.card {
    transition: all 0.8s ease;
    box-shadow: rgba(0, 0, 0, 0.10) 0px 5px 10px;
}

.card .card-text {
    font-size: 14px;
}

#bundles .col-md-4 {
    margin-bottom: 2%;
}

#writeforus .lazy-load-image-background {
    width: 100%;
}

.disable {
    cursor: not-allowed;
    pointer-events: none;
}

#div2 .col-md-3 {
    margin-bottom: 3%;
}

#courses .col-md-3 {
    margin-bottom: 3%;
}

.learnworlds-subheading-large {
    font-family: "Barlow";
}

#specific .col-auto {
    margin-bottom: 2%;
}

#loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url(https://www.disneyinstitute.com/assets/images/loading.420575d6.gif) 50% 50% no-repeat rgb(255, 255, 255, 0.5);
}