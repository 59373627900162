.modal img {
    width: 100%;
    margin: 0;
}

ul li a {
    font-size: 15px;
    color: black;
    font-weight: bold;
}

table {
    box-shadow: 0 0 11px rgba(33, 33, 33, .3);
}

#butn {
    border-radius: 30px;
    background: linear-gradient(45deg, black, red 88%, rgb(224, 224, 224) 0px);
}

#btn {
    border-radius: 30px;
    background: linear-gradient(90deg, black, red 100%, rgb(224, 224, 224) 0px);
}

.card a {
    color: black;
}

a:hover {
    text-decoration: none;
}

.btn-primary:hover {
    background-color: #32CDE1;
}

.card {
    border-radius: 10px;
    transition: box-shadow .9s;
    box-shadow: 0 0 11px rgba(33, 33, 33, .3);
}

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url(https://www.disneyinstitute.com/assets/images/loading.420575d6.gif) 50% 50% no-repeat rgb(255, 255, 255, 0.5);
}