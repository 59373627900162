a:hover {
    text-decoration: none;
}

.card {
    transition: all 0.8s ease;
    box-shadow: rgba(0, 0, 0, 0.10) 0px 5px 10px;
}

.card .card-text {
    font-size: 14px;
}

#bundles .col-md-4 {
    margin-bottom: 2%;
}

#div2 .col-md-3 {
    margin-bottom: 3%;
}

#courses .col-md-3 {
    margin-bottom: 2%;
}

.learnworlds-subheading-large {
    font-family: "Barlow";
    text-shadow: 1px 1px 5px white;
}

#products li {
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 10px;
}

#products li a {
    font-size: 20px;
    font-weight: normal;
}