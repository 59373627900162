#specific .col-md-3 {
    margin-bottom: 3%;
}

#loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url(https://www.disneyinstitute.com/assets/images/loading.420575d6.gif) 50% 50% no-repeat rgb(255, 255, 255, 0.5);
}