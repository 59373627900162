.c1 p,
.c2 p,
.c3 p,
.c4 p,
.c5 p,
.c6 p,
.c7 p,
.c8 p,
.c9 p,
.c10 p,
.c11 p,
.c12 p,
.c13 p,
.c14 p,
.c15 p,
.c15 p,
.c16 p,
.c17 p,
.c18 p,
.c19 p,
.c20 p,
.c21 p,
.c22 p {
    border-bottom: 1px solid whitesmoke;
}

#icons .col-auto {
    text-shadow: 1px 1px 1px red;
}

.learnworlds-subheading-large {
    font-family: "Barlow";
    text-shadow: 1px 1px 5px red;
}

#coursedetails p {
    text-align: justify;
}

.guruscourses p:hover {
    color: blue;
    cursor: pointer !important;
}

.error {
    border: 3px solid red;
}

#loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url(https://www.disneyinstitute.com/assets/images/loading.420575d6.gif) 50% 50% no-repeat rgb(255, 255, 255, 0.5);
}