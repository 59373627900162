.card {
    transition: all 0.8s ease;
    box-shadow: rgba(0, 0, 0, 0.10) 0px 5px 10px;
}

#blogs .col-md-4 {
    margin-bottom: 3%;
}

.b-p {
    text-align: justify;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.b-p ol li {
    margin-bottom: 2%;
}

.b-p p {
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}